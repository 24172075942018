import { css } from '@emotion/react';
import * as React from 'react';
import type { EventItemFragment } from '../../common-client-queries.generated';
import { ColoredLabel } from '../colored-label';

export type CompleteLabelStatus = 'complete' | 'overridden' | 'incomplete';

export const bookkeepingLabels: Record<CompleteLabelStatus, string> = {
  complete: 'Ferdig til regnskapet',
  overridden: 'Merket som ferdig',
  incomplete: 'Mangler noe til regnskapet',
};

// Used if we don't have an event, e.g. when creating a payment
export function getCompletionStatus(flags: {
  allRequirementsMet: boolean;
  overridden: boolean;
}): CompleteLabelStatus {
  const { allRequirementsMet, overridden } = flags;

  const completionStatus: CompleteLabelStatus = overridden
    ? 'overridden'
    : allRequirementsMet
    ? 'complete'
    : 'incomplete';

  return completionStatus;
}

// Used if we have an event
export function getEventCompletionStatus(event: EventItemFragment) {
  const {
    completed,
    requirements: {
      requirementsOverridden: { overridden },
    },
  } = event;

  return getCompletionStatus({ allRequirementsMet: completed, overridden });
}

const validLabelStatuses: CompleteLabelStatus[] = ['complete', 'overridden'];

export const CompleteLabel: React.FC<{
  status: CompleteLabelStatus;
}> = ({ status }) => {
  const isValidStatus = validLabelStatuses.includes(status);
  const [visible, setVisible] = React.useState(isValidStatus);
  const [fade, setFade] = React.useState(isValidStatus);
  const [displayedStatus, setDisplayedStatus] = React.useState(
    isValidStatus ? status : null,
  );

  React.useEffect(() => {
    if (isValidStatus) {
      setDisplayedStatus(status);
      setVisible(true);
      const fadeInTimer = setTimeout(() => setFade(true), 50);
      return () => clearTimeout(fadeInTimer);
    } else if (status === 'incomplete') {
      setFade(false);
      const timer = setTimeout(() => setVisible(false), 400);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [status, isValidStatus]);

  if (!visible || !displayedStatus) return null;

  return (
    <ColoredLabel
      aria-hidden={status === 'incomplete'}
      css={css`
        transition: opacity 0.4s ease;
        opacity: ${fade ? 1 : 0};
        display: ${visible ? 'block' : 'none'};
      `}
      color="green"
    >
      {bookkeepingLabels[displayedStatus]}
    </ColoredLabel>
  );
};

export const ReservedLabel: React.FC = () => {
  return (
    <ColoredLabel
      data-testid="reserved-label"
      color="yellow"
      css={css`
        width: fit-content;
      `}
    >
      Reservert betaling
    </ColoredLabel>
  );
};

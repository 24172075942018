import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { groupItemsByDate, useExpandedEvents } from 'bank-common-client';
import { VisuallyHidden, space } from 'folio-common-components';
import * as React from 'react';
import { SkeletonLine } from '../../components/SkeletonLine';
import { cardStyle } from '../../components/card-style';
import { stickyHeader } from '../../styles/sticky-header';
import { SkeletonEventSummaryRow } from './EventSummaryRow';
import { SalaryPaymentTransactionRow, TransactionRow } from './TransactionRow';
import type {
  EventItemOrSalaryPaymentItem,
  EventItemOrSalaryPaymentList,
  SalaryPaymentItem,
} from './types';

export const Item = styled.div`
  ${cardStyle};
  overflow: visible;
`;

export const StickyHeader = styled.h3`
  ${stickyHeader};
`;

function isSalaryPayment(
  event: EventItemOrSalaryPaymentItem,
): event is SalaryPaymentItem {
  return event.__typename === 'SalaryPayment';
}

function statusText(eventsCount: number, incompleteEventsCount: number) {
  const word = eventsCount === 1 ? 'bevegelse' : 'bevegelser';
  if (incompleteEventsCount > 0) {
    return `${eventsCount} ${word}, ${incompleteEventsCount} mangler noe`;
  } else {
    return `${eventsCount} ${word}`;
  }
}

export const TransactionList: React.FC<{
  events: EventItemOrSalaryPaymentList;
  incompleteCount?: number;
}> = ({ events, incompleteCount = 0 }) => {
  const eventsByDate = React.useMemo(() => {
    return groupItemsByDate(events, 'dateTime');
  }, [events]);

  const expandedEvents = useExpandedEvents();

  return (
    <>
      <VisuallyHidden aria-live="polite" aria-atomic="true">
        {statusText(events.length, incompleteCount)}
      </VisuallyHidden>
      {Array.from(eventsByDate.entries()).map(
        ([date, events], index, array) => (
          <div
            key={date}
            css={css`
              padding-bottom: ${index === array.length - 1 ? null : '10px'};
            `}
          >
            <StickyHeader>{date}</StickyHeader>
            {events.map(event => (
              <Item key={event.fid} data-testid="Item">
                {isSalaryPayment(event) ? (
                  <SalaryPaymentTransactionRow
                    bulkEvent={event}
                    expanded={expandedEvents.has(event.fid)}
                  />
                ) : (
                  <TransactionRow
                    event={event}
                    expanded={expandedEvents.has(event.fid)}
                    backlink="Oversikt"
                  />
                )}
              </Item>
            ))}
          </div>
        ),
      )}
    </>
  );
};

export const LoadingTransactionList: React.FC<{
  count: number;
}> = props => (
  <div data-testid="loading-transaction-list">
    <StickyHeader>
      <SkeletonLine width="150px" maxWidth="100%" />
    </StickyHeader>
    {Array.from({ length: Math.min(20, props.count) }).map((_, index) => (
      <LoadingTransaction key={index} index={index} />
    ))}
  </div>
);

export const LoadingTransaction: React.FC<{
  index: number;
}> = ({ index }) => (
  <Item key={index}>
    <div css={space([16], 'margin-bottom')}>
      <SkeletonEventSummaryRow index={index} />
    </div>
  </Item>
);

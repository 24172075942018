import type { Card, Cards } from '../components/CardTopUp';

export function accountHasBlockedCard(cards: Cards): Card | undefined {
  const anyActiveOrInactive = cards.some(
    card => card.state === 'Active' || card.state === 'NotActivated',
  );

  // if there is any active or non-activated cards, don't say it's blocked
  if (anyActiveOrInactive) {
    return undefined;
  }

  return cards.find(
    card => card.state === 'Blocked' || card.state === 'FraudBlocked',
  );
}

// If the user has multiple cards, check if all of them are expired
export function allCardsAreExpired(cards: Cards): boolean {
  if (cards.length === 0) {
    return false;
  }
  return cards.every(card => isCardExpired(card));
}
export function isCardExpired(card?: Card | null): boolean {
  if (!card) {
    return false;
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return (
    card.expirationYear < currentYear ||
    (card.expirationYear === currentYear && card.expirationMonth < currentMonth)
  );
}

import { useFirstPaintData } from '../hooks/use-first-paint-data';
import {
  BroadcastBoxHeading,
  BroadcastBoxWrapper,
  BroadcastWrapper,
} from './Broadcast';
import { ChatButton } from './chat-button';

export const OverdueInvoiceReminder: React.FC = () => {
  const { data } = useFirstPaintData();

  const overdueInvoiceDetails =
    data?.organization.subscriptionInformation?.overdueInvoiceDetails;

  if (overdueInvoiceDetails == null) {
    return;
  }

  const { overdueInvoiceCount, notificationStage } = overdueInvoiceDetails;
  if (overdueInvoiceCount === 0) {
    return;
  }

  return (
    <BroadcastBoxWrapper>
      <BroadcastWrapper severity="Error">
        {notificationStage <= 2 ? (
          <>
            <BroadcastBoxHeading>
              Du har ikke betalt for Folio
            </BroadcastBoxHeading>
            Det var ikke dekning på drifts&shy;konto. Overfør penger, eller{' '}
            <ChatButton>spør oss</ChatButton> hvis noe ikke stemmer.
          </>
        ) : (
          <>
            <BroadcastBoxHeading>
              Denne kontoen kan bli stengt
            </BroadcastBoxHeading>
            Du har ikke betalt for Folio. Vi har prøvd å nå deg gjentatte
            ganger. <ChatButton>Ta kontakt med oss</ChatButton>.
          </>
        )}
      </BroadcastWrapper>
    </BroadcastBoxWrapper>
  );
};

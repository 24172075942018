import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OverlayContainer } from '@react-aria/overlays';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import {
  Button,
  NumberValueInput,
  PlainButton,
  ProgressSpinner,
  fonts,
  space,
} from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useHasCapability } from '../../hooks/use-capability-check';
import { useTransactionAndBalanceRefetchConfig } from '../../hooks/use-transaction-and-balance-refetch-config';
import { XForCloseIcon } from '../../icons';
import { pages } from '../../pages';
import { ApprovePaymentDocument } from '../../queries.generated';
import { BankIdSign } from '../../routes/payment/BankIdSign';
import { accountHasBlockedCard } from '../../utils/card';
import { ModalDialog, dialogHeadingStyle } from '../modal-dialog';
import { OrgLink } from '../org-navigation';
import { AutofillInfoScreen } from './autofill-info-screen';
import { AutofillStateScreen } from './autofill-state-screen';
import { EmployeePayDocument } from './queries.generated';
import { LoadingScreen } from './shared';
import type { AccountType, Card } from './types';

const narrowMq = '(min-width: 400px)';
const veryNarrowMq = '(min-width: 360px)';

export type { AccountsType, AccountType, Card, Cards } from './types';

type State = {
  view:
    | 'autofill-state'
    | 'editing-balance'
    | 'loading'
    | 'signing'
    | 'autofill-info';
  newBalance: string;
  paymentFid: string | null;
  signingUrl: string | null;
  autofillData: AccountType['autofill'];
  balanceBeforeTransfer: string;
  showAutofillFirst: boolean;
  mustVerifyAutofillState: boolean;
};

type Action =
  | { type: 'set-payment-fid'; fid: string | null }
  | { type: 'show-loading-view' }
  | { type: 'show-edit-view' }
  | {
      type: 'show-signing-view';
      signingUrl: string | null;
    }
  | { type: 'set-transfer-succeeded' }
  | { type: 'set-transfer-failed' }
  | { type: 'dismiss-autofill-dialog' }
  | { type: 'set-new-balance'; balance: string }
  | { type: 'set-balance-before-transfer'; balance: string }
  | { type: 'autofill-refreshed-after-top-up' };

// This is completely arbitrary.
const maxBalance = 999_999;
const maxBalanceLength = formatters.formatAmount(maxBalance).length;

const reducer: React.Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case 'show-loading-view':
      return { ...prevState, view: 'loading' };

    case 'set-payment-fid':
      return { ...prevState, paymentFid: action.fid };

    case 'show-edit-view':
      return {
        ...prevState,
        newBalance: '',
        signingUrl: null,
        view: 'editing-balance',
      };

    case 'show-signing-view':
      return {
        ...prevState,
        view: 'signing',
        signingUrl: action.signingUrl,
      };

    case 'set-transfer-succeeded': {
      if (prevState.showAutofillFirst) {
        return {
          ...prevState,
          view: 'autofill-state',
          mustVerifyAutofillState: true,
        };
      }

      if (prevState.autofillData == null) {
        return {
          ...prevState,
          view: 'editing-balance',
          signingUrl: null,
          newBalance: '',
        };
      }

      return {
        ...prevState,
        view: 'autofill-info',
        signingUrl: null,
      };
    }

    case 'autofill-refreshed-after-top-up': {
      return { ...prevState, mustVerifyAutofillState: false };
    }

    case 'dismiss-autofill-dialog':
      return { ...prevState, view: 'editing-balance', newBalance: '' };

    case 'set-transfer-failed':
      return { ...prevState, view: 'editing-balance', signingUrl: null };

    case 'set-new-balance': {
      if (action.balance === '') {
        return { ...prevState, newBalance: '' };
      } else {
        const newBalanceAsNumber = Math.trunc(Number(action.balance));
        const newBalance = Math.min(newBalanceAsNumber, maxBalance);
        return {
          ...prevState,
          newBalance: String(newBalance),
        };
      }
    }

    case 'set-balance-before-transfer':
      return { ...prevState, balanceBeforeTransfer: action.balance };
  }
};

export type OnPaymentStatus = (
  status: 'success' | 'failure' | 'autofill-handled',
  account: AccountType,
) => void;

interface TopUpProps {
  mainAccount: string;
  account: AccountType;
  showAutofillFirst: boolean;
  showHeading?: boolean;
  onPaymentStatus: OnPaymentStatus;
}

interface TopUpFormProps extends TopUpProps {
  canSetAutofill: boolean;
}

export const CardTopUp: React.FC<TopUpProps> = props => {
  const canSetAutofill = useHasCapability('CanSetAutofill');

  if (canSetAutofill == null) {
    return null;
  }

  return <CardTopUpForm canSetAutofill={canSetAutofill} {...props} />;
};

const BlockedCardView: React.FC<{ card: Card }> = ({ card }) => {
  const isFraud = card.state === 'FraudBlocked';

  return (
    <>
      {isFraud ? (
        <div
          css={css`
            color: ${colors.wcagRed};
          `}
        >
          Kortet er midlertidig sperret
        </div>
      ) : (
        <div>Fjern frys for å åpne kortet.</div>
      )}
      <OrgLink
        css={css`
          color: var(--muted-color);
          /* Needed for the margin to take effect */
          display: inline-block;
          margin-top: 8px;
        `}
        to={
          isFraud
            ? pages.profile.getUrl()
            : `${pages.profile.getUrl()}#frys-kort`
        }
      >
        Vis valg for kortet
      </OrgLink>
    </>
  );
};

const CardTopUpForm: React.FC<TopUpFormProps> = ({
  mainAccount,
  account,
  canSetAutofill,
  showAutofillFirst,
  showHeading = false,
  onPaymentStatus,
}) => {
  const currentBalance = account.balanceNok?.asNumber
    ? String(account.balanceNok.asNumber)
    : '0';
  const [state, dispatch] = React.useReducer(reducer, {
    view:
      canSetAutofill && showAutofillFirst
        ? 'autofill-state'
        : 'editing-balance',
    newBalance: '',
    paymentFid: null,
    signingUrl: null,
    autofillData: account.autofill,
    balanceBeforeTransfer: currentBalance,
    showAutofillFirst,
    mustVerifyAutofillState: false,
  });
  const skipAutofillPrompt =
    showAutofillFirst || !canSetAutofill || state.autofillData == null;

  const transactionAndBalanceRefetchConfig =
    useTransactionAndBalanceRefetchConfig();

  // In cases where we don't need to auth the transfer using BankID,
  // we end up refetching twice. That's fine.
  const [pay] = useMutation(
    EmployeePayDocument,
    transactionAndBalanceRefetchConfig,
  );

  const isEditingBalance = state.view === 'editing-balance';

  React.useEffect(() => {
    if (isEditingBalance) {
      dispatch({
        type: 'set-balance-before-transfer',
        balance: currentBalance,
      });
    }
  }, [currentBalance, isEditingBalance]);

  const success = React.useCallback(() => {
    dispatch({ type: 'set-transfer-succeeded' });
    onPaymentStatus('success', account);
  }, [onPaymentStatus, account]);

  const failure = React.useCallback(() => {
    dispatch({ type: 'set-transfer-failed' });
    onPaymentStatus('failure', account);
  }, [onPaymentStatus, account]);

  const handleSetNewBalance = React.useCallback(async () => {
    if (state.newBalance === '') {
      return;
    }

    // TODO: move some default values to backend
    try {
      let debtorAccount = mainAccount;
      let creditorAccount = account.accountNumber;
      let creditorName = account.name;

      const amountToTransfer = getAmountToTransfer(
        currentBalance,
        state.newBalance,
      );

      if (amountToTransfer < 0) {
        [debtorAccount, creditorAccount] = [creditorAccount, debtorAccount];
        creditorName = 'Driftskonto'; // FIXME
      } else if (amountToTransfer === 0) {
        // TODO: maybe some status message here
        return;
      }

      dispatch({ type: 'show-loading-view' });

      const res = await pay({
        awaitRefetchQueries: true,
        variables: {
          input: {
            debtorAccount,
            creditorAccount,
            creditorName,
            amount: String(Math.abs(amountToTransfer)),
            date: format(new Date(), 'yyyy-MM-dd'),
            message: 'Overføring',
            signingMethod: 'BankId',
            hidden: true,
          },
        },
      });
      const data = res ? res.data : null;
      if (data) {
        dispatch({ type: 'set-payment-fid', fid: data.pay.paymentFid });
        const signingUrl = data.pay.authUrl;
        // Missing signingUrl means that no signing is needed
        if (signingUrl == null || signingUrl === '') {
          success();
          if (skipAutofillPrompt) {
            onPaymentStatus('autofill-handled', account);
          }
        } else {
          dispatch({
            type: 'show-signing-view',
            signingUrl,
          });
        }
      }
    } catch (error) {
      failure();
      onPaymentStatus('autofill-handled', account);
      Sentry.captureException(error);
    }
  }, [
    account,
    currentBalance,
    mainAccount,
    onPaymentStatus,
    success,
    failure,
    pay,
    skipAutofillPrompt,
    state.newBalance,
  ]);

  const autofillHandled = React.useCallback(
    () => dispatch({ type: 'autofill-refreshed-after-top-up' }),
    [],
  );

  if (state.view === 'autofill-state') {
    const accountHasAnyBlockedCards = accountHasBlockedCard(account.cards);

    // this would be wrong for employees view, but in that case we never render
    // top up view for blocked cards
    if (accountHasAnyBlockedCards) {
      return <BlockedCardView card={accountHasAnyBlockedCards} />;
    }

    const newAccountBalance = Number(state.newBalance);
    return (
      <AutofillStateScreen
        account={account}
        startManualTransfer={() => dispatch({ type: 'show-edit-view' })}
        mustVerifyAutofillState={state.mustVerifyAutofillState}
        autofillHandled={autofillHandled}
        newAccountBalance={newAccountBalance}
        moneyIncreased={newAccountBalance > Number(state.balanceBeforeTransfer)}
      />
    );
  }

  if (state.view === 'autofill-info') {
    const newAccountBalance = Number(state.newBalance);
    return (
      <AutofillInfoScreen
        newAccountBalance={newAccountBalance}
        account={account}
        moneyIncreased={newAccountBalance > Number(state.balanceBeforeTransfer)}
        onClose={() => {
          onPaymentStatus('autofill-handled', account);
          dispatch({ type: 'dismiss-autofill-dialog' });
        }}
      />
    );
  }

  const id = `account-${account.accountNumber}`;
  return (
    <>
      {showHeading && (
        <div
          css={css`
            ${fonts.font200medium};
            ${space([16], 'margin-bottom')};
          `}
        >
          Overfør fra driftskonto
        </div>
      )}
      <form
        key={account.accountNumber}
        noValidate={true}
        onSubmit={event => {
          event.preventDefault();
          handleSetNewBalance();
        }}
      >
        <Label htmlFor={id}>Hvor mye skal stå på kortet?</Label>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 8px;
            gap: 8px;
          `}
        >
          <NewBalanceInput
            id={id}
            value={state.newBalance}
            onChange={newBalance =>
              dispatch({ type: 'set-new-balance', balance: newBalance })
            }
            maxLength={maxBalanceLength}
            autoComplete="off"
            name="new-balance"
            placeholder={formatters.formatAmount(Number(currentBalance))}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
          />
          <Button type="submit">OK</Button>
        </div>
        <p
          css={css`
            margin: 16px 0 0;
            color: var(--muted-color);
            ${fonts.font100book};
          `}
        >
          For å overføre tilbake til drifts&shy;konto, velger du lavere saldo på
          kortet.
        </p>
      </form>
      {state.view === 'signing' && state.paymentFid != null && (
        <SigningModalDialog
          signingUrl={state.signingUrl}
          paymentFid={state.paymentFid}
          onSuccess={() => success()}
          onFailure={() => failure()}
          onSigningChange={signingUrl => {
            dispatch({ type: 'show-signing-view', signingUrl });
          }}
        />
      )}
      {state.view === 'loading' && (
        <LoadingScreen>
          <ProgressSpinner size={48} />
        </LoadingScreen>
      )}
    </>
  );
};

const SigningModalDialog: React.FC<{
  signingUrl: State['signingUrl'];
  paymentFid: string;
  onSuccess: () => void;
  onFailure: () => void;
  onSigningChange: (signingUrl: State['signingUrl']) => void;
}> = props => {
  const { signingUrl, onSuccess, onFailure, onSigningChange } = props;
  const transactionAndBalanceRefetchConfig =
    useTransactionAndBalanceRefetchConfig();
  const [approvePayment] = useMutation(
    ApprovePaymentDocument,
    transactionAndBalanceRefetchConfig,
  );

  return (
    <OverlayContainer>
      <ModalDialog
        title="Godkjenn overføring med BankID"
        isOpen
        css={dialogMarginAdjustments}
      >
        <div css={dialogMarginAdjustments}>
          <DialogHeader>
            <div css={dialogHeadingStyle}>Godkjenn overføring med BankID</div>
            <PlainButton aria-label="Lukk" onClick={() => onFailure()}>
              <XForCloseIcon
                css={css`
                  display: block;
                `}
              />
            </PlainButton>
          </DialogHeader>
          <BankIdSign
            url={signingUrl ?? undefined}
            onSuccess={async (code, replyState) => {
              // This will re-render with no signing URL, which renders a spinner
              onSigningChange(null);

              try {
                await approvePayment({
                  variables: {
                    input: { code, state: replyState },
                  },
                });
                onSuccess();
              } catch (error) {
                onFailure();
                Sentry.captureException(error);
              }
            }}
            onError={error => {
              onFailure();
              Sentry.captureException(error);
            }}
          />
        </div>
      </ModalDialog>
    </OverlayContainer>
  );
};

export function toNumberWithFallback(amount: string) {
  return amount === '' ? 0 : Number(amount);
}

export function getAmountToTransfer(
  currentBalance: string,
  newBalance: string,
): number {
  const currentBalanceAsNumber = Number(currentBalance);
  const newBalanceAsNumber = toNumberWithFallback(newBalance);
  let amountToTransfer = 0;
  // If the new balance is 0, we assume the account should be completely
  // emptied, so don't do any rounding.
  if (newBalanceAsNumber === 0) {
    amountToTransfer = -currentBalanceAsNumber;
  } else {
    amountToTransfer =
      Math.trunc(newBalanceAsNumber) - Math.trunc(currentBalanceAsNumber);
  }

  return amountToTransfer;
}

const NewBalanceInput = styled(NumberValueInput)`
  text-align: initial;
  width: 100%;
`;

const Label = styled.label`
  ${fonts.font200medium};
  display: inline-block;
  padding-bottom: 4px;
`;

const DialogHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${space([16], 'margin-bottom')};

  @media not all and ${veryNarrowMq} {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

// The BankID iframe is only responsive down to 320px. This is used to adjust
// margins of both the dialog and the iframe so that the whole iframe fits on
// devices that are 320px wide.
const dialogMarginAdjustments = css`
  @media not all and ${narrowMq} {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }

  @media not all and ${veryNarrowMq} {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`;
